@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');

.tob-bar-left {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end; }

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s; }

.trigger:hover {
  color: #1890ff;
  width: fit-content; }

.logo {
  height: 32px;
  margin: 16px; }

.site-layout .site-layout-background {
  background: #fff; }

.c-main-bar {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .c-main-bar span {
    height: fit-content; }

.logo-img-layout {
  max-width: 170px;
  max-height: 30px;
}

.main-menu-item-cus {
  background: #6495ED;
}

.main-menu-item-cus-normal > ul {
  background: #333333 !important;
}

.main-menu-item-cus-normal > div {
  background: #2b2b2b !important;
  padding-left: 10px !important;
}

.main-menu-item-cus > div {
  background: #6495ED !important;
  color: white;
  padding-left: 10px !important;
}

.main-menu-item-cus > div > a {
  color: white;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.cus-menu-item-li {
  background: #6495ED !important;
  color: white;
}

.ant-menu-submenu-title {
  padding: 0 !important;
}

.ant-menu-submenu-title  > a {
  display:inline-block;
  padding-left: 15px;
}

.ant-menu-submenu > ul > li > .ant-menu-submenu-title  > a{
  display:inline-block;
  padding-left: 30px;
}

.ant-menu-item {
  padding-left: 45px !important;
}

.ant-layout-sider-children > ul {
  max-height: 85%;
  overflow-y: scroll;
}

.ant-layout-sider-children {
  background: #042c62;
}

.ant-layout-sider-children > ul {
  background: #042c62 !important;
}

.ant-page-header-content {
  padding: 0;
}

* {
  box-sizing: border-box;
}

.vertical-middle {
  vertical-align: middle;
}

/*.ant-layout-sider-children > ul::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

/*::-webkit-scrollbar{*/
/*  width: 0;*/
/*}*/
/*::-webkit-scrollbar {*/
/*  width: 4px;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*  -webkit-box-shadow: none;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background: grey;*/
/*  opacity: 0.5;*/
/*}*/

/*::-webkit-scrollbar-thumb:window-inactive {*/
/*  background: none;*/
/*}*/

.sider-panel-footer {
  background: #042c62;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sider-panel-footer > button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #042c62;
}

.ant-menu-root > .ant-menu-title-content {
  padding-left: 10px;
}
